<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    data-nw="subscription-product"
    :class="[
      'subscription-product d-flex flex-column p-32 mr-16 ml-16 mb-24',
      { 'subscription-product--premium': isPremium },
    ]"
  >
    <div>
      <p v-if="isPremium" class="orange-label fw-bold mb-8">
        {{ $label('user.signUp.mostChosen') }}
      </p>
      <h3 v-if="isPremium">
        {{ product.name }}
      </h3>
      <h4 v-else>
        {{ product.name }}
      </h4>
    </div>

    <div class="mt-48">
      <p class="text-banner mb-0">{{ product.priceText }}</p>
      <p class="text-large mb-0">/{{ $label('common.month') }}</p>
    </div>

    <ul class="mt-32 mb-48 styled-list" v-html="product.discountText"></ul>

    <BaseButton
      class="card-button"
      :data-nw="isPremium ? 'premium-product' : null"
      :disabled="addingToCart"
      :class="{ loading: addingToCart }"
      :variant="buttonVariant"
      @click="select(product)"
    >
      {{ $label(isPremium ? preSelectedBtnLabel : selectBtnLabel) }}
    </BaseButton>
  </div>
</template>

<script>
import currencyFormatter from '~/utils/currencyFormatter'

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    showSelectBtn: {
      type: Boolean,
      default: true,
    },
    preSelectedBtnLabel: {
      type: String,
      default: 'subscription.choose.preSelectedBtn',
    },
    selectBtnLabel: {
      type: String,
      default: 'subscription.choose.selectBtn',
    },
    onSelect: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      addingToCart: false,
    }
  },
  computed: {
    monthlyPrice() {
      return currencyFormatter(this.product.monthlyPrice)
    },
    isPremium() {
      return this.product.isPreselected
    },
    buttonVariant() {
      return this.isPremium ? 'call-to-action' : 'secondary'
    },
  },
  methods: {
    async select(product) {
      this.addingToCart = true

      await this.onSelect(product)

      this.addingToCart = false
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-product {
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 8px;
  width: 359px;
}

.orange-label {
  color: $orange-600;
}

.text-large {
  color: $grey-600;
}

@include media-breakpoint-down(md) {
  .subscription-product {
    order: 2;
  }

  .subscription-product--premium {
    order: 1;
  }
}
</style>
